html {
	background-color: #f4f4f4;
	font-family: 'Open Sans', sans-serif;
	font-size: 81.25%;
}

h1, h2, h3 {
	color: #15345E;
}

h1 {
	font-size: 24px;
	line-height: 33px;
	font-weight: 300;
}

h2 {
	font-size: 14px;
	line-height: 19px;
}

input,
select {
	padding: 1rem;
	border: 1px solid #C3C3C3;
	border-radius: 3px;
	background-color: #F4F4F4;
	box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.3);
}

label {
	display: block;
	margin-top: 1.5rem;
	margin-bottom: 0.25rem;
	color: #15345E;
}

a {
	color: #15345E;
}

a:hover {
	color: rgb(41, 107, 133);
	text-decoration: none;
}

/* === Button === */
.button {
	padding: 1rem 2rem;
	border-radius: 3px;
	background-color: #15345E;
	color: #ffffff;
	border: none;
	cursor: pointer;
}

.button:hover {
	background-color: #234a81;
}

.button svg {
	width: 1.5em;
	height: 1.5em;
	margin: -0.3em 0.5em -0.3em 0;
	top: 0.125em;
  position: relative;
}

.button--transparent {
	background: none;
	color: #15345E;
}

.button--transparent:hover {
	background: none;
}

.button--icon {
	padding: 0.25em 0.2em;
	vertical-align: middle;
	opacity: 0.3;
}

.button--icon:hover {
	opacity: 1;
}

.button--icon svg {
	margin-right: 0;
}

/* === Layout === */
.layout {
	margin: 5rem auto 0 auto;
	width: 100%;
	max-width: 500px;
	padding: 0 1rem;
}

.header {
	display: -webkit-flex;
	display: flex;
	margin: 3rem auto;
	max-width: 1300px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.header__logo {
	width: 200px;
}

.header__user {
	display: inline-block;
	margin-right: 2rem;
}

.loader {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1002;
}

/* === Box === */
.box__paper {
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
  border-radius: 3px;
	padding: 2rem 2rem 3rem 2rem;

}

@media screen and (min-width: 720px) {
	.box__paper {
		padding: 2rem 9rem 4rem 9rem;
	}
}

.box__paper--wide {
	padding-left: 2rem;
	padding-right: 2rem;
}

.box__heading {
	margin-bottom: 3rem;
	text-align: center;
}

.box__renew {
	display: block;
	margin-top: 2rem;
	text-align: center;
}

/* === Category === */
.category {
	margin-bottom: 3rem;
}

.category__heading {
	position: relative;
	padding: 0 5rem;
}

.category__heading-text {
	text-align: center;
}

.category__heading-controls {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
}

/* === Subcategory === */
.subcategory {
	margin-bottom: 1rem;
	background-color: #ffffff;
	border-radius: 3px;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

.subcategory__header {
	margin: 0;
	padding: 1.5rem 2rem;
	font-weight: bold;
	font-size: 1.08rem;
	cursor: pointer;
}

.subcategory__header:hover {
	background-color: #dddddd;
}

.subcategory__heading {
	display: -webkit-flex;
	display: flex;
	margin: 0;
}

.subcategory__heading-text {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.subcategory__heading-controls {
	margin-left: 1rem;
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;
}

.subcategory__content {
	display: none;
	padding: 0 2rem 1rem 2rem;
}

.subcategory--active .subcategory__content {
	display: block;
}

.subcategory .subcategory__plus,
.subcategory.subcategory--active .subcategory__minus {
	display: inline-block;
}

.subcategory .subcategory__minus,
.subcategory.subcategory--active .subcategory__plus {
	display: none;
}

/* === Document === */
.document {
	margin: 0.5rem 0;
}

.document__link {
	display: inline-block;
	line-height: 1.5rem;
}

.document__icon {
	position: relative;
	top: -0.1em;
	margin-right: 0.5em;
	vertical-align: middle;
}

.document__controls {
	display: inline-block;
	position: relative;
	top: -0.1em;
	margin-left: 1rem;
	vertical-align: middle;
}

/* === System message === */
.sysmsg {
	position: fixed;
	left: 2rem;
	bottom: 2rem;
	max-width: 400px;
	z-index: 1002;
}

.sysmsg__box {
	position: relative;
	width: 100%;
	background-color: orange;
	color: #000000;
	padding: 1.5rem 4rem 1.5rem 3rem;
	border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

.sysmsg__box--error {
	color: #ffffff;
	background-color: #ea3737;
}

.sysmsg__box--success {
	background-color: green;
	color: #ffffff;
}

.sysmsg__close {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	width: 16px;
	height: 16px;
	line-height: 16px;
	padding: 2px;
	cursor: pointer;
	opacity: 0.5;
}

.sysmsg__close:hover {
	opacity: 1;
}

/* === Modal === */
.modal {
	width: 500px;
	padding: 0rem 3rem 2rem 3rem;
	box-sizing: border-box;
	border-radius: 3px;
}

.modal__header {
	margin: 0 -3rem 2rem -3rem;
	padding: 1rem;
	background-color: #f0f0f0;
	border-radius: 3px 3px 0 0;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: bold;
}

.modal__button {
	margin-top: 1rem;
	text-align: center;
}

.modal__close {
	cursor: pointer;
}

/* === Dropzone === */
.dropzone {
	display: inline-block;
}

/* === List === */
.list {
	margin: 4rem 0;
}

.list--nomargin {
	margin: 0;
}

.list__item {
	margin: 0.5rem 0;
}

/* === Table === */
table.table {
	width: 100%;
}

table.table th,
table.table td {
	padding: 2px;
}

table.table tr:nth-child(even) {
	background-color: #eeeeee;
}


/* === Helpers === */
.h-text-center {
	text-align: center;
}

.h-text-left {
	text-align: left;
}

.h-text-right {
	text-align: right;
}

.h-full-width {
	width: 100%;
	box-sizing: border-box;
}

.h-mt-big {
	margin-top: 3rem;
}

.h-mt-small {
	margin-top: 0.5rem;
}

.h-icon {
	vertical-align: middle;
	margin-right: 0.5rem;
}
